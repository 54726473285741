import './App.css';
import {
  MessagedataCreateForm 
 } from './ui-components';
import { Amplify } from 'aws-amplify';
import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

import awsExports from './aws-exports';
import React from 'react';
Amplify.configure(awsExports);


function App({ signOut, user }) {
  return (

    <div>
      <h1>Hello {user.username}</h1>

      <MessagedataCreateForm/>
      <button onClick={signOut}>Sign out</button>
      </div>
  );
}

export default withAuthenticator(App);

