// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';



const { Predictionagreement, Messagedata } = initSchema(schema);

export {
  Predictionagreement,
  Messagedata
};